import React from "react"

type tagProp = {
    title: string,
    tags: Array<string>
}

const TechTags: React.FC<tagProp> = ({ title, tags }) => (
    <div className="mb-3 card">
        <div className="card-content">
            <span className="subtitle">{title}</span>
            <div className="flex flex-wrap justify-start">
                {tags.map((tag) =>
                    <div className="m-1">
                        <span className="tag is-light">{tag}</span>
                    </div>
                )}
            </div>
        </div>
    </div>
)

export default TechTags
