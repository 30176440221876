import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import TechTags from "../../components/techTags";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1><a parentName="h1" {...{
        "href": "https://biobot.farm"
      }}>{`biobot.farm`}</a></h1>
    <h2>{`Summary`}</h2>
    <p>{`I started my journey at biobot in 2018, this is a biotech startup from México, I was hire as an intern.
My initial task was, get the sign-up email verification working, this was my first time looking at a big
monolithic codebase, I was overwhelmed by the amount of code and the complexity of the system. I didn't
understand much of the code, so the first month I was puting extra hours every day to get better at the
stack and codebase.`}</p>
    <p>{`Because of all the extra hours, I good proficient quickly, this was great for school and work, I was really
enjoying my job and programming. As I continued working, scripting became an essential aspect of my job.
I was creating complex algorithms to process data and trigger actions, I'm really proud of the notification
system that I created for the Biobots, this devices where gathering data on the state of plants, and with this
new notification system, users where able to set specific threasholds to be notify about.`}</p>
    <p>{`Another project that I lead technically  was to architect the functionality of a smart center pivot gathering system.
This project involved remote control of the center pivot and real-time monitoring, and it was a great accomplishment for me.
This was my first systems architecture from the ground up, I was able to design the system, implement it, and deploy it.
Also this project was the first time that I contributed to an open-source project. `}</p>
    <p>{`Moreover, this experience allowed me to explore DevOps, and I took the opportunity
to learn more about Linux, AWS, and networking. I would often visit the Sysadmin
office whenever possible to observe how scripts, APIs, or microservices were deployed,
and this helped me gain knowledge and skills in the field.`}</p>
    <TechTags title="Jobs" tags={["Backend Dev", "Product Owner", "Scrum master", "Devops"]} mdxType="TechTags" />
    <h2>{`Challenges`}</h2>
    <p>{`The journey had a lot of challenges and these are some of them. They aren't in order of difficulty`}</p>
    <ul>
      <li parentName="ul">{`Developing my first Python library`}</li>
      <li parentName="ul">{`Working with code that wasn't mine`}</li>
      <li parentName="ul">{`Managing People `}</li>
      <li parentName="ul">{`Code for the goals not for tech`}</li>
      <li parentName="ul">{`First Steps on big Rest API`}</li>
      <li parentName="ul">{`IOT `}</li>
      <li parentName="ul">{`Realtime monitoring `}</li>
      <li parentName="ul">{`Multi-Lingual Product`}</li>
    </ul>
    <TechTags title="Technologies" tags={["Python", "Docker", "Mysql", "S3", "Twilio", "Campaign monitor", "nginx", "Apache", "PHP"]} mdxType="TechTags" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      